import * as React from "react";
import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const Page = ({ location }) => {
  return (
    <Layout path={location.pathname} title="לוח זמנים – כולל הזנקות">
      <SectionContent title="לוח זמנים – כולל הזנקות">
        <p>6:00 &ndash; זינוק שליחים ( כל הקבוצות ) &ndash; אנדרטת הפלדה</p>
        <p>
          10:00 &ndash; זינוק 2 ק&quot;מ עממי ילדי מועצה אזורית אשכול
          &nbsp;נתיבות ומבואות נגב &ndash; בארי הישנה
        </p>
        <p>
          10:30 &ndash; זינוק 2 ק&quot;מ עממי ילדי המועצה האזורית שער הנגב
          ושדרות וחוף אשקלון &nbsp;&ndash; אנדרטת חץ שחור
        </p>
        <p>15:30 &ndash; טקס סיום &ndash; חוף זיקים</p>
      </SectionContent>
    </Layout>
  );
};

export default Page;
